import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'swiper/css/swiper.min.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import "./assets/mod.css"

// import './assets/media.css'
Vue.use(VueAwesomeSwiper)
Vue.use(Antd);
// 挂载网络请求类
import axios from 'axios'
axios.defaults.baseURL = 'https://m-api.yunzhanggui.com/api/'
Vue.prototype.$axios = axios

 // 配置白名单

const whiteList = ['/', '/yzgInstructions', '/aboutUs', '/userDemo', '/detailCase', '/college']

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
      if(to.name === 'userDemo'){
        console.log(to,'to');
        document.title = to.query.title + "-上海宿程信息科技有限公司"
      }else{
        document.title = to.meta.title + "-上海宿程信息科技有限公司"
      }
  }else {
      document.title = '云掌柜-上海宿程信息科技有限公司"'
  }
  next()
  if(to.meta.title){
    if(to.name === 'detailCase'){
      document.title =  to.meta.title + '-'  + to.query.title + "-上海宿程信息科技有限公司"
    }
  }

   // 访问不存在的页面去首页
  if (whiteList.includes(to.path)) {
    next()
  } else {
    next('/')
  }
});

Vue.config.productionTip = false
Vue.prototype.$EventBus = new Vue()
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
